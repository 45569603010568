<template>
  <div>
    <HeaderPanel
      title="STAFF MANAGEMENT"
      :filter="filter"
      placeholder="Search Staff ID, Name"
      @search="getPersonList"
      @sidebar="sidebarFilter"
      :hasDropdown="false"
      routerPath="/manageStaff/detail/0"
    />
    <div class="bg-white border-red px-1 px-sm-3 pb-3 mt-3">
      <Table
        :filter="filter"
        :pageOptions="pageOptions"
        :fields="fields"
        :items="items"
        :isBusy="isBusy"
        :rows="rows"
        :showingTo="showingTo"
        @filterPage="filterPage"
        @handleCheckAll="handleCheckAll"
      />
    </div>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      @searchAll="getPersonList"
      placeholder="Search Staff ID, Name"
      @clearFilter="clearFilter"
    >
      <template v-slot:checkbox-option> </template>
    </SideBarFilter>
  </div>
</template>
<script>
import HeaderPanel from "@/components/HeaderPanel";
import OtherLoading from "@/components/loading/OtherLoading";
import Table from "@/components/staff/Table";
import SideBarFilter from "@/components/SideBarFilter";

export default {
  components: {
    OtherLoading,
    HeaderPanel,
    SideBarFilter,
    Table,
  },
  data() {
    return {
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
        status: [],
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          key: "sales_no",
          label: "Staff ID",
        },
        {
          key: "first_name",
          label: "Name",
        },

        {
          key: "active",
          label: "Status",
        },
        {
          key: "actions",
          label: ""
        },
      ],
      items: [],
    };
  },

  created() {
    this.getPersonList();
  },
  methods: {
    handleCheckAll(val) {
      this.items.map((el) => el.isChecked == val);
      for (const items of this.items) {
        items.isChecked = val;
      }

      this.items = [...this.items];
    },
    getPersonList() {
      this.isBusy = true;

      this.$Axios
        .post(`${process.env.VUE_APP_API}/salesperson/list`, this.filter)
        .then((res) => {
          if (res.data.result) {
            this.rows = res.data.detail.total;
            this.items = res.data.detail.data;
            this.isBusy = false;
          }
        });
    },

    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },

    filterPage(filter) {
      this.filter = filter;
      this.getPersonList();
    },
    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
        status: [],
      };
    },
  },
};
</script>
